import { FlowComponent, JSX } from "solid-js";

declare namespace FeaturesProvider {
  type Props = {
    featureProviders: FlowComponent[];
    children: JSX.Element;
  };
}

function FeaturesProvider(props: FeaturesProvider.Props) {
  return (function nest(providers = props.featureProviders, i = 0) {
    if (i === providers.length) return props.children;
    const Provider = providers[i];
    if (!Provider) return nest(providers, i + 1);
    return <Provider>{nest(providers, i + 1)}</Provider>;
  })();
}

export default FeaturesProvider;
