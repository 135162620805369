export const featureProviders = Object.values(
  // prettier-ignore
  import.meta.glob("./features/*", {
    import: "Provider",
    eager: true,
  }) as {
/* GENERATED */ "./features/admin.ts": typeof import("./features/admin.ts")["Provider"],
/* GENERATED */ "./features/creditLines.ts": typeof import("./features/creditLines.ts")["Provider"],
/* GENERATED */ "./features/payments.ts": typeof import("./features/payments.ts")["Provider"],
/* GENERATED */ "./features/users.ts": typeof import("./features/users.ts")["Provider"]
},
);
