import { JSX } from "solid-js";
import c from "class-c";

import Icon from "@repo/utils/Icon";

import Card from "../containers/Card";

declare namespace Alert {
  type Type = "error" | "warning" | "info";
  type Props = Card.Props & {
    class?: string;
    type: Type;
    icon?: Icon;
    children: JSX.Element;
  };
}

function Alert({
  class: className,
  type,
  icon = Alert.iconByType[type],
  children,
  ...props
}: D<Alert.Props>) {
  return (
    <Card
      class={c`row ${className}`}
      outline={type !== "info" && `var(--${type})`}
      {...props}
    >
      <div class={`mr-1.5 color:${type === "info" ? "strong" : type}`}>
        {icon}
      </div>
      <div class="expand">{children}</div>
    </Card>
  );
}

Alert.iconByType = {
  error: "",
  warning: "",
  info: "",
} satisfies Record<Alert.Props["type"], Icon>;

export default Alert;
