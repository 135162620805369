import "@repo/utils/global/polyfills";

import { FlowComponent, JSX, onCleanup, Show } from "solid-js";
import { createStore } from "solid-js/store";
import { Motion, Presence } from "solid-motionone";
import overlayApi from "solid-overlays";

import PendingMigrationError from "@repo/api/PendingMigrationError";
import FeatureStore from "@repo/utils-solid/FeatureStore";

import Button from "../buttons/Button";
import ContainerButton from "../buttons/ContainerButton";
import LoadableButton from "../buttons/LoadableButton";
import FeaturesProvider from "../context/FeaturesProvider";
import { ErrorProvider, useError } from "../error/ErrorProvider";
import Alert from "../info/Alert";

import { invalidate } from "./signals";

import "solid-overlays/index.css";
import "./GlobalRoot.scss";

FeatureStore.configure({
  useCaptureError: () => {
    const error = useError();
    return (e) => {
      if (!(typeof e === "string" || e instanceof Error))
        throw new Error(
          `Attempted to capture error with unsupported type: ${e}`,
        );
      return error.capture(e);
    };
  },
});

declare namespace GlobalRoot {
  type Props = {
    OverlaysProvider: ReturnType<
      ReturnType<typeof overlayApi<{}>>["create"]
    >["OverlaysProvider"];
    featureProviders: FlowComponent[];
    children: JSX.Element;
  };
}

function GlobalRoot(props: GlobalRoot.Props) {
  const [store, setStore] = createStore({
    error: null as string | Error | null,
    show: false,
  });

  return (
    <div id="global-root">
      {(() => (
        invalidate.listen(),
        (
          <ErrorProvider
            onError={(error) => {
              // Don't show empty error message
              if (!(typeof error === "string" ? error : error.message)) return;
              setStore({ error, show: !!error });
            }}
          >
            <props.OverlaysProvider>
              <FeaturesProvider featureProviders={props.featureProviders}>
                {props.children}
              </FeaturesProvider>
            </props.OverlaysProvider>
            <Presence>
              <Show when={store.show}>
                <Motion.div
                  id="error"
                  class="center-child"
                  initial={{ opacity: 0, y: 24 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 24 }}
                >
                  <ContainerButton
                    class="fit-content"
                    onClick={() => {
                      setStore("show", false);
                    }}
                  >
                    <Alert type="error" class="p-2">
                      {(() => {
                        const { error } = store;
                        if (!error) return;
                        if (
                          import.meta.env.DEV &&
                          error instanceof PendingMigrationError
                        )
                          return (
                            <div class="row-center">
                              {error.message}
                              <LoadableButton
                                as={Button}
                                size="small"
                                variant="primary-hard"
                                type="submit"
                                style={{ margin: "-8px 0 -8px 16px" }}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  return error.fix().then(() => {
                                    LoadableButton.afterSuccess(() => {
                                      setStore("show", false);
                                      invalidate();
                                    });
                                  });
                                }}
                              >
                                Run migrations
                              </LoadableButton>
                            </div>
                          );

                        // We intentionally don't want to time out migration error
                        const t = setTimeout(() => {
                          setStore("show", false);
                        }, 5000);

                        onCleanup(() => {
                          clearTimeout(t);
                        });

                        if (typeof error === "string") return error;
                        return error.message;
                      })()}
                    </Alert>
                  </ContainerButton>
                </Motion.div>
              </Show>
            </Presence>
          </ErrorProvider>
        )
      ))()}
    </div>
  );
}

export default GlobalRoot;
